import { GetData, PutData } from 'apiHandler'
import TableComponent from 'components/Table'
import React, { useState, useEffect } from 'react'
import { Card, CardBody, Container, Col, Row, Modal } from 'reactstrap'
import moment from 'moment'
import { getAdminStatusString, getWDStatusString } from '../../../src/pages/Orders/Common'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import NumberFormat from 'react-number-format'

function InvoicingDetails () {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [reqData, setReqData] = useState()
  const [selectedData, setSelectedData] = useState({})
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const userObj = JSON.parse(localStorage.authUser)
  const location = useLocation()
  const params = useParams()

  React.useEffect(() => {
    setReqData(location.state)
  }, [location])

  useEffect(() => {
    reqData && getDetails()
  }, [reqData])
  // eslint-disable-next-line no-unused-vars
  const [currentRole, setCurrentRole] = useState(1)
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [details, setDetails] = useState([])
  const [columns, setColumns] = useState([
    { key: 'orderNumber', label: 'Order ID', sorting: true },
    { key: 'orderDate', label: 'Order Date', sorting: true },
    { key: 'orderStatus', label: 'Order Status', sorting: false },
    { key: 'invoiceStatus', label: 'Invoice Status', sorting: false },
    { key: 'costPriceTotal', label: 'Order Value', sorting: false },
    { key: 'costPriceInvoiceTotal', label: 'Invoice Value', sorting: false }
  ])
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  useEffect(() => {
    if (localStorage.authUser) {
      const userObj = JSON.parse(localStorage.authUser)
      setCurrentRole(userObj.roleId)
    }
  }, [])
  const month = params?.id
  const year = reqData?.year
  const vendorId = reqData?.vendorId

  const handlePaymentStatus = (e, orderId, status) => {
    // call api to change status
    const url = 'admin/updateInvoiceStatus'
    const body = {
      vendorOrderId: orderId,
      invoiceStatus: 1
    }
    PutData(url, body)
      .then((response) => {
        if (response.status && response.code === 200) {
          toastr.success(response.message, 'Success')
          getDetails(paging.currentPage)
        } else {
          toastr.error(response.message, 'Error')
        }
      })
  }

  const selectPaymentString = (status, orderId) => {
    return <select style={{ width: '80%', padding: '5px 10px', borderRadius: '4px' }} disabled={parseInt(status) === 1} onChange={(e) => handlePaymentStatus(e, orderId, status)}>
      <option style={{ fontSize: '16px' }} value="0" selected={parseInt(status) === 0}>Pending</option>
      <option style={{ fontSize: '16px' }} value="1" selected={parseInt(status) === 1}>Paid</option>
    </select>
  }

  const getpaymentString = (status) => {
    let statusString = ''
    if (status === 0) {
      statusString = <span className="disabled-div"> Pending</span>
    }
    if (status === 1) {
      statusString = <span className="active-div">Paid</span>
    }
    return statusString
  }

  const getDetails = (p = 1) => {
    setLoader(true)
    const userObj = JSON.parse(localStorage.authUser)
    const pageSize = 20
    let url = 'admin/getInvoiceForMonth?page=' + p + '&pageSize=' + pageSize
    if (userObj.roleId === 3 || userObj.roleId === 2) {
      url = 'vendor/getInvoiceForMonth?page=' + p + '&pageSize=' + pageSize
      setColumns([
        { key: 'orderNumber', label: 'Order ID', sorting: true },
        { key: 'orderDate', label: 'Order Date', sorting: true },
        { key: 'orderStatus', label: 'Order Status', sorting: false },
        { key: 'invoiceStatus', label: 'Invoice Status', sorting: false },
        { key: 'costPriceTotal', label: 'Order Value', sorting: false },
        { key: 'costPriceInvoiceTotal', label: 'Invoice Value', sorting: false }
      ])
    }
    if (month !== null) {
      url += '&month=' + month
    }
    if (year !== null) {
      url += '&year=' + year
    }
    if (userObj.roleId === 1) {
      if (vendorId !== null) {
        url += '&vendorId=' + vendorId
      }
    }
    // if (vendorId !== null) {
    //   url += '&vendorId=' + vendorId
    // }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order.toUpperCase()
    }
    GetData(url).then((response) => {
      if (response.status === true) {
        setLoader(false)
        const tableData = []
        response?.result.orders?.map((item, i) => {
          const invoiceStatus = (userObj.roleId === 3 || userObj.roleId === 4) ? getpaymentString(item.invoiceStatus) : selectPaymentString(item.invoiceStatus, item.vendorOrderId)
          tableData.push({
            orderNumber: <span onClick={(e, k = i) => handleRow(tableData[k])}><a style={{ color: '#0069a7' }}>{ item?.parentOrder.orderNumber }</a></span>,
            orderDate: moment(item.createdAt).format('Do MMMM YYYY, h:mm A'),
            orderStatus: (userObj.roleId === 3 || userObj.roleId === 4) ? getWDStatusString(item.orderStatus) : getAdminStatusString(item.orderStatus),
            invoiceStatus,
            createdAt: item?.createdAt,
            customer: item?.parentOrder?.shippingFirstName + ' ' + item?.parentOrder?.shippingLastName,
            costPriceTotal: item.costPriceTotal ? '$' + item.costPriceTotal : '--',
            total: item?.costPriceInvoiceTotal  || item?.costPriceTotal ? <NumberFormat
            value={(
              (parseFloat(item?.costPriceInvoiceTotal) || parseFloat(item?.costPriceTotal) || 0) + 
              (parseFloat(item?.parentOrder?.shipping) || 0)
            )}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            prefix={'$'}
          />: '--',
            costPriceInvoiceTotal: item.costPriceInvoiceTotal ? '$' + item.costPriceInvoiceTotal : '--',
            deliveredDate: item.deliveredDate ? moment(item.deliveredDate).format('Do MMMM YYYY, h:mm A') : '--',
            shippingCharges: item?.parentOrder?.shipping ? '$' + item?.parentOrder?.shipping : '--'
          })
          setDetails(tableData)
          return null
        })
        setPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: response.result.total
        })
      } else {
        setLoader(false)
        setDetails([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
      }
    })
  }
  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }
  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getDetails(1)
  }, [sortingState])

  const paginationHandler = (page) => {
    getDetails(page)
  }
  const exportDetails = () => {
    const userObj = JSON.parse(localStorage.authUser)
    let newUrl = ''
    if (userObj.roleId === 1) {
      newUrl = 'admin/export/getInvoiceForMonth?month=' + month + '&year=' + year
    }
    if (userObj.roleId === 2 || userObj.roleId === 3) {
      newUrl += 'vendor/export/getInvoiceForMonth?month=' + month + '&year=' + year
    }
    if (userObj.roleId === 1) {
      newUrl += '&vendorId=' + vendorId
    }
    GetData(newUrl).then((response) => {
      setLoader(true)
      if (response.status === true) {
        setLoader(false)
        if (userObj.roleId === 2 || userObj.roleId === 3) {
          history.push({ pathname: '/invoicing-Details-pdf', state: { data: response.result, value: 'Wd' } })
        } else {
          history.push({ pathname: '/invoicing-Details-pdf', state: { data: response.result, value: 'admin' } })
        }
        // const a = document.createElement('a')
        // a.target = '_blank'
        // a.href = 'https://' + response.result
        // a.download = 'download'
        // a.click()
      }
    })
  }

  // const handleExport = () => {
  //   const userObj = JSON.parse(localStorage.authUser)
  //   let newUrl = ''
  //   if (userObj.roleId === 1) {
  //     newUrl = 'admin/export/getInvoiceForMonth?month=' + month + '&year=' + year
  //   }
  //   if (userObj.roleId === 2 || userObj.roleId === 3) {
  //     newUrl += 'vendor/export/getInvoiceForMonth?month=' + month + '&year=' + year
  //   }
  //   if (userObj.roleId === 1) {
  //     newUrl += '&vendorId=' + vendorId
  //   }
  //   GetData(newUrl).then((response) => {
  //     setLoader(true)
  //     if (response.status === true) {
  //       setLoader(false)
  //       const a = document.createElement('a')
  //       a.target = '_blank'
  //       a.href = 'https://' + response.result.path
  //       a.download = 'download'
  //       a.click()
  //     }
  //   })
  // }

  const handleRow = (data) => {
    setSelectedData(data)
    setIsDisableOpen(true)
  }
  // function modalData (k, shipdata) {
  //   const vendorOrderId = shipdata.vendorOrderId
  //   const modalUrl = 'admin/getOrder?vendorOrderId=' + vendorOrderId
  //   console.log(shipdata.vendorOrderId)
  //   GetData(modalUrl).then((response) => {
  //     if (response.status === true) {
  //       setModalDetails(response.result)
  //       console.log(response.result)
  //     }
  //   })
  // }
  return (
        <>
        <Loader show={loader} />

        <div className="page-content">
        <div>
        <Modal
                  className="modal-main-body"
                  isOpen={isDisableOpen}
                  toggle={() => setIsDisableOpen(false)}
                  centered
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => {
                        setIsDisableOpen(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-detail-div">
                      <p className="modal-details">Order Details</p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Order Number: </span>{' '}
                        {selectedData?.orderNumber ? selectedData?.orderNumber : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Customer Name: </span>{' '}
                        {selectedData?.customer ? selectedData?.customer : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Order Value: </span>{' '}
                        {selectedData?.costPriceTotal ? selectedData?.costPriceTotal : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Invoice Value: </span>{' '}
                        {selectedData?.costPriceInvoiceTotal ? selectedData?.costPriceInvoiceTotal : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Shipping Value: </span>{' '}
                        {selectedData?.shippingCharges ? selectedData?.shippingCharges : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Total: </span>{' '}
                        {selectedData?.total ? selectedData?.total : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Created At: </span>{' '}
                        {selectedData?.createdAt ? moment(selectedData?.createdAt).format('MM/DD/YYYY') : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Order Status: </span>{' '}
                        {
                          selectedData?.orderStatus ? selectedData?.orderStatus : '--'
                        }
                      </p>
                    </div>
                  </div>
                </Modal>
    </div>
            <Container fluid={true} className="customer-container">
             <div className="products-nav">
                <div className="products-heading d-flex align-items-center">
                <div className='pt-1'><i
            onClick={() => history.push('/invoicing')}
            style={{ cursor: 'pointer' }}
            className="dripicons-arrow-thin-left me-3"
          ></i></div>
                  {
                    params.id === '1'
                      ? <span>January</span>
                      : params.id === '2'
                        ? <span>February</span>
                        : params.id === '3'
                          ? <span>March</span>
                          : params.id === '4'
                            ? <span>April</span>
                            : params.id === '5'
                              ? <span>May</span>
                              : params.id === '6'
                                ? <span>June</span>
                                : params.id === '7'
                                  ? <span>July</span>
                                  : params.id === '8'
                                    ? <span>August</span>
                                    : params.id === '9'
                                      ? <span>September</span>
                                      : params.id === '10'
                                        ? <span>October</span>
                                        : params.id === '11'
                                          ? <span>November</span>
                                          : params.id === '12'
                                            ? <span>December</span>
                                            : ''

                  }
                {' '} <span style={{ marginLeft: '5px' }}>Invoice</span></div>
                <div>
                {/* <button className="products-nav-btn products-export-btn"
                onClick={handleExport}
                >
                  Export
                </button> */}
                  <button className='invoicing-download-btn' onClick={exportDetails}>Download</button></div>
                </div>
                <Row className="mt-3">
                    <Col lg={12}>
                    <Card>
                        <CardBody>
                        <TableComponent
                 columns={columns}
                 content={details}
                 paging={paging}
                 paginationHandler={paginationHandler}
                 sorting={handleSort}
                 arrowState={sortingState}
                />
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
  )
}
export default InvoicingDetails
