/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledTooltip
} from 'reactstrap'
import classnames from 'classnames'
import BulkUploadModal from 'components/Common/BulkUploadModal/BulkUploadModal'
import { GetData, PostData } from 'apiHandler'
import TableComponent from 'components/Table'
import { useHistory, Link } from 'react-router-dom'
import Loader from '../../components/Loader'
import toastr from 'toastr'
// import toolimg from '../../assets/images/Bitmap.png'
import 'toastr/build/toastr.min.css'

let searchTimer
let filterByKeyObject = { key: 'all' }

const ProductTable = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const [singlebtn, setSinglebtn] = useState(false)
  const [tab, setTab] = useState('1')
  const [products, setProducts] = useState([])
  const [loader, setLoader] = useState(false)
  const [filterTypes, setFilterTypes] = useState([])
  const [exportState, setExportState] = useState([])
  const [bulkError, setBulkError] = useState('')
  const [bulkErrorSheet, setBulkErrorSheet] = useState('')
  const [filterByStatus, setFilterByStatus] = useState('active')
  const [activeCount, setActiveCount] = useState(0)
  const [notListedCount, setNotListedCount] = useState(0)
  const userObj = JSON.parse(localStorage.authUser)
  // const sheetUrl = 'https://docs.google.com/spreadsheets/d/1xXP2IG5lwOcarGCtLWYjpRr5CFa6sZ_4y0Q9epKxUg0/edit#gid=2114097500'
  // const sheetUrlAdmin = 'https://docs.google.com/spreadsheets/d/17EF-3e0wUz7PhAXGV4HN7UOj9ZFBC8btSrbmeTWMbYc/edit?usp=sharing'
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const [selectedFilter, setSelectedFilter] = useState({
    value: 'all',
    key: 'All'
  })
  const [columns, setColumns] = useState([
    { key: 'partNumber', label: 'Part Number' },
    { key: 'product', label: 'Product' },
    { key: 'brand', label: 'Brand', sorting: true },
    { key: 'category', label: 'Category', sorting: true },
    { key: 'subcategory', label: 'Sub Category', sorting: true },
    { key: 'avgPartCostPrice', label: 'Avg. Cost Price', sorting: false },
    { key: 'sell_price', label: 'Selling Price', sorting: true, input: true },
    { key: 'quantity', label: 'Qty', sorting: true },
    { key: 'uom', label: 'UoM' }
  ])

  useEffect(() => {
    return () => {
      setTab('1')
      filterByKeyObject = { key: 'all' }
    }
  }, [])

  useEffect(() => {
    getProducts(1)
  }, [filterByStatus])

  const navHandle = (key, status) => e => {
    e.preventDefault()
    setTab(key)
    setProducts([])
    setPaging({
      currentPage: 1,
      recordPerPage: 20,
      totalRecord: 0
    })
    setFilterByStatus(status)
  }

  const dropToggle = () => {
    setSinglebtn(!singlebtn)
  }

  const handleActions = (key, data) => {
    if (key === 'view') {
      history.push('/products/' + data.id)
    }
  }

  const handleRow = (data) => {
    history.push('/products/' + data.id)
  }

  const paginationHandler = (page) => {
    getProducts(page)
  }

  useEffect(() => {
    if (!bulkError) {
      setBulkError('')
    }
  }, [bulkError])
  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.value
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getProducts(1)
    }
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getProducts(paging.currentPage)
  }, [sortingState])

  const updateSellPrice = (e, data, price, page) => {
    e.preventDefault()
    let body = {
      part_id: data.id,
      sell_price: price,
      BrandID: data?.BrandID
    }
    let url = 'admin/updateSellPrice'
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      body = {
        part_id: data.id,
        price,
        BrandID: data?.BrandID
      }
      url = 'vendor/updateCostPrice'
    }
    setLoader(true)
    PostData(url, body).then((response) => {
      if (response.status === true) {
        setLoader(false)
        getProducts(page)
        toastr.success(response.message, 'Success')
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getProducts = (p = 1) => {
    setLoader(true)
    const pageSize = 20
    const userObj = JSON.parse(localStorage.authUser)
    let url = 'admin/getProductList?page=' + p + '&pageSize=' + pageSize
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      if (filterByStatus === 'active') {
        url = 'vendor/getProductList?page=' + p + '&pageSize=' + pageSize + '&selectedTab=' + filterByStatus
        setColumns([
          { key: 'partNumber', label: 'Part Number' },
          { key: 'product', label: 'Product' },
          { key: 'brand', label: 'Brand', sorting: true },
          { key: 'category', label: 'Category', sorting: true },
          { key: 'subcategory', label: 'Sub Category', sorting: true },
          { key: 'price', label: 'WD Price', sorting: true },
          { key: 'quantity', label: 'Qty', sorting: true },
          { key: 'uom', label: 'UoM' }
        ])
      } else {
        url = 'vendor/getProductNotListed?page=' + p + '&pageSize=' + pageSize + '&selectedTab=' + filterByStatus
        setColumns([
          { key: 'partNumber', label: 'Part Number' },
          { key: 'product', label: 'Product' },
          { key: 'brand', label: 'Brand', sorting: true },
          { key: 'category', label: 'Category', sorting: true },
          { key: 'subcategory', label: 'Sub Category', sorting: true },
          { key: 'price', label: 'WD Price', sorting: true, input: true },
          { key: 'quantity', label: 'Qty', sorting: true },
          { key: 'uom', label: 'UoM' }
        ])
      }
    }
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    }
    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setFilterTypes(response.result.filterTitle)
        setLoader(false)
        setExportState(response.result.products.map((product) => {
          return product.part_id
        }))
        if (filterByStatus === 'active') {
          setActiveCount(response.result.total)
          setNotListedCount(response.result.notActiveCount)
        } else {
          setActiveCount(response.result.activeCount)
          setNotListedCount(response.result.total)
        }
        if (userObj.roleId === 3 || userObj.roleId === 4) {
          setProducts(response.result.products.map((product) => {
            return {
              id: product.part_id,
              product: product.PartTerminology.PartTerminologyName,
              partNumber: product.partNumber,
              brand: product?.brand,
              category: product.partCategoryMapping.partCategory.categoryName,
              subcategory: product.partCategoryMapping.partSubCategory.subCategoryName,
              price: (filterByStatus !== 'active' ? 0 : '$' + product.price.cost_price),
              quantity: product?.quantity?.toString(),
              uom: 'Each',
              BrandID: product?.BrandID
            }
          }))
        } else {
          setProducts(response.result.products.map((product, index) => {
            return {
              id: product.part_id,
              product: product.PartTerminology.PartTerminologyName,
              partNumber: product.partNumber,
              brand: product?.brand,
              category: product.partCategoryMapping.partCategory.categoryName,
              avgPartCostPrice: product?.avgPartCostPrice
                ? <div className=""><span id={`tooltip-${index}`} className="d-flex align-items-center">{product?.avgPartCostPrice} <i className="ms-1 bx bxs-info-circle" style={{ fontSize: '18px' }}></i></span>
                <UncontrolledTooltip
                  placement="left"
                  target={`tooltip-${index}`}
                  autohide={false}
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    boxShadow:
                      '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
                    minWidth: '250px',
                    fontSize: '13px'
                  }}>
                  <div className="tooltiptext1 arrow">
                    <div className='tooltipdata d-flex align-items-center justify-content-between mb-2'>
                      <span className='part--id'><b>Company Name</b></span>
                      <span className='part--id pe-1'><b>Cost Price</b></span>
                    </div>
                    <div className='tooltip-innerdata' id="tooltip-scrollBar" style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                      {
                        product?.vendorsData?.map((data, key) => {
                          return (
                            <div className='partid-qty-div d-flex align-items-center justify-content-between mb-1' key={key}>
                               <span>{data?.vendorPersonalDetail?.vendorDetails?.companyName}</span>
                                <span className="pe-1">{data?.cost_price}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  {/* {product?.vendorsData?.map((data, i) => {
                    return <span key={i}>{data?.cost_price}</span>
                  })} */}
                </UncontrolledTooltip></div>
                : '--',
              subcategory: product.partCategoryMapping.partSubCategory.subCategoryName,
              sellingPrice: product.price?.sell_price,
              quantity: product?.quantity?.toString(),
              uom: 'Each',
              BrandID: product?.BrandID
            }
          }))
        }
        setPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: response.result.total
        })
      } else {
        setLoader(false)
        setProducts([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getProducts(1)
    }, 2000)
  }

  const importSellingPrice = (e, file) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('document', file)
    setLoader(true)
    PostData('admin/import/sellPrice', formData).then((response) => {
      if (response.status === true) {
        getProducts(1)
        toastr.success(response?.message, 'Success')
        setBulkError(false)
      } else {
        setBulkError(response?.message)
        setBulkErrorSheet(response?.result?.url)
        toastr.error(response?.message, 'Error')
        setLoader(false)
      }
    })
  }

  const productsBulkUpload = (e, file) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('document', file)
    setLoader(true)
    PostData('vendor/import/product', formData, true).then((response) => {
      if (response.status === true) {
        getProducts(1)
        setBulkError(false)
        toastr.success(response.message, 'Success')
      } else {
        setBulkError(response?.message)
        setBulkErrorSheet(response?.result?.url)
        toastr.error(response?.message, 'Error')
        setLoader(false)
      }
    })
  }

  function handleExport (e) {
    e.preventDefault()
    setLoader(true)
    let url = 'admin/export/products'
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = 'vendor/export/products'
    }
    PostData(url, (userObj.roleId === 3 || userObj.roleId === 4) ? { type: filterByStatus } : { productsIds: exportState }).then(
      (response) => {
        if (response.status === true) {
          setLoader(false)
          const a = document.createElement('a')
          a.href = 'https://' + response.result
          a.download = 'download'
          a.click()
        } else {
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      }
    )
  }

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <span className="products-heading">Products</span>
            <div className='d-flex'>
              {(userObj.roleId === 3 || userObj.roleId === 4)
                ? <BulkUploadModal modalType='Bulk Products Upload' productsBulkUpload={productsBulkUpload} bulkError={bulkError} bulkErrorSheet={bulkErrorSheet} />
                : <BulkUploadModal modalType='Import Products Detail' importSellingPrice={importSellingPrice} bulkError={bulkError} bulkErrorSheet={bulkErrorSheet} />}
              <button className="products-nav-btn products-export-btn" onClick={handleExport}>
                Export
              </button>
              {/* {(userObj.roleId === 3 || userObj.roleId === 4) && <Link to="/add-product"> <button className="products-nav-btn add--products--btn ms-2">
                + Add Products
              </button>
              </Link>} */}
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar">
                    <Nav>
                      {(userObj.roleId === 3 || userObj.roleId === 4)
                        ? <>
                          <NavItem className='navItems'>
                            <NavLink
                              className={classnames({
                                active: tab === '1'
                              })}
                              onClick={navHandle('1', 'active')}
                            >
                              Active ({activeCount})
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: tab === '2'
                              })}
                              onClick={navHandle('2', 'notlisted')}
                            >
                              Not Listed ({notListedCount})
                            </NavLink>
                          </NavItem></>
                        : <NavItem className='navItems'>
                          <NavLink
                            className={classnames({
                              active: tab === '1'
                            })}
                          >
                            All ({paging.totalRecord})
                          </NavLink>
                        </NavItem>}

                    </Nav>
                    <div className="d-flex product_nav">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={dropToggle}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}<span className="font-weight-bold">
                            {selectedFilter.key}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.key}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  <TableComponent
                    columns={columns}
                    content={products}
                    handleActions={handleActions}
                    updateSellingPrice={updateSellPrice}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    onClick={handleRow}
                    sorting={handleSort}
                    arrowState={sortingState}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ProductTable
